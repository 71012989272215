import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from '@justin-s/ngx-intl-tel-input';
import { CreateAccountFormComponent } from '../../../auth/components/create-account-form/create-account-form.component';
import { BaseComponent } from '@ea/components';
import { AddressEditorComponent } from '@ea/address-editor';
import { AuthFacade, AuthService } from '@ea/auth';
import { TRequestAddressData } from '@ea/models';
import { ToastService } from '@ea/services';

@Component({
  selector: 'ea-cart-create-account',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    FormsModule,
    NgxIntlTelInputModule,
    CreateAccountFormComponent,
    AddressEditorComponent,
  ],
  templateUrl: './cart-create-account.component.html',
  styleUrls: ['./cart-create-account.component.scss'],
})
export class CartCreateAccountComponent extends BaseComponent {
  @ViewChild(CreateAccountFormComponent)
  public RegisterComponent!: CreateAccountFormComponent;

  @ViewChild(AddressEditorComponent)
  public AddressEditorComponent!: AddressEditorComponent;

  constructor(private authFacade: AuthFacade,     private authService: AuthService,    private router: Router,    private toasterService: ToastService,) {
    super();
  }

  isFormValid() {
    return this.RegisterComponent.isFormValid();
  }

  createAccount() {
    //how i can get registerForm
    if (!this.isFormValid()) return;
    //check if form valid then

    this.AddressEditorComponent.newAddressForm.patchValue({
      additionalAttributes: {
        first: this.RegisterComponent.registerForm.value.firstName,
        last: this.RegisterComponent.registerForm.value.lastName,
        phone: this.RegisterComponent.registerForm.value.phone.number
      }
    });
    
    const  addressData: TRequestAddressData = {
      ...this.AddressEditorComponent.newAddressForm.value,
      first:this.RegisterComponent.registerForm.value.firstName,
      last: this.RegisterComponent.registerForm.value.lastName,
      nickName: this.AddressEditorComponent.newAddressForm.value.additionalAttributes.nickName,
      isDefaultAddress: this.AddressEditorComponent.newAddressForm.get('isDefaultAddress')?.value,
      phone: this.AddressEditorComponent.newAddressForm.value.phone.e164Number
        ? this.AddressEditorComponent.newAddressForm.value.phone.e164Number
        : this.AddressEditorComponent.newAddressForm.value.phone,
        isBillingAddress: true,
        isShippingAddress: true
    };
    addressData.phone = this.RegisterComponent.registerForm.value.phone.number;
    const registerData = this.RegisterComponent.registerForm.value;
    let payload = {
      accountForm: registerData,
      addressForm: addressData,
    };
    this.isButtonsDisabled$.next(true);
    this.authService.registerWithAddress(payload).subscribe(
      x=>{ if(x.response){
        this.isButtonsDisabled$.next(false);
        this.router.navigateByUrl('/thank-you')
      }
      else{
        this.toasterService.show(
          'Sorry, We were unable to register your account.',
          'error'
        );
      }
            },
            e=>{
              this.isButtonsDisabled$.next(false);
              this.toasterService.show(
                'Sorry, We were unable to register your account.',
                'error'
              );
            }
    )
  //  this.authFacade.createAccount(payload);
  }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { AuthFacade, AuthService } from '@ea/auth';
import {
  BreadcrumbComponent,
  BaseComponent,
  ToastComponent,
} from '@ea/components';
import { Toast, UpdateUser } from '@ea/models';
import { LocalStorageService, ToastService } from '@ea/services';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedModule } from '../../../shared';
import { CartService } from 'src/app/modules/cart/services';
import { UtilsService } from '@ea/utils';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  CountryISO,
  NgxIntlTelInputModule,
  PhoneNumberFormat,
  SearchCountryField,
} from '@justin-s/ngx-intl-tel-input';
@Component({
  selector: 'ea-account-details-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    FormsModule,
    BreadcrumbComponent,
    SharedModule,
    NgxSkeletonLoaderModule,
    NgxIntlTelInputModule,
    ToastComponent,
  ],
  templateUrl: './account-details-page.component.html',
  styleUrls: ['./account-details-page.component.scss'],
})
export class AccountDetailsPageComponent extends BaseComponent {
  utils = UtilsService;
  accountForm: FormGroup;
  email: string = '';
  separateDialCode = false;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  isLoading$ = new BehaviorSubject<boolean>(true);
  toast?: Toast;
  closeToast() {
    this.toast = undefined;
  }
  constructor(
    private builder: FormBuilder,
    private auth: AuthService,
    private storage: LocalStorageService,
    private authfacde: AuthFacade,
    private router: Router,
    private toasterService: ToastService,
    private readonly service: CartService,
  ) {
    super();
    this.accountForm = this.builder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      allowEmail: [],
    });
    this.auth.getProfile().subscribe((results: any) => {
      this.email = results.emailAddress;
      this.accountForm.patchValue({
        firstName: results.firstName,
        lastName: results.lastName,
        email: this.email,
        phone: results.phone,
        allowEmail: results.emailMarketing,
      });
      this.accountForm.controls['email'].disable();
      this.isLoading$.next(false);
    });
    this.toasterService._accountToast$.subscribe((toast) => {
      this.toast = toast;
    });
  }
  ChangePassword() {
    this.toggleDisable(true);
    this.auth
      .chanePasswordAuthUset(this.storage.getItem('customId'))
      .subscribe({
        next: (x) => {
          this.router.navigate(['/password-reset/' + x.token]);
        },
        error: () => {
          this.toggleDisable(false);
        },
      });
  }
  submit(): void {
    this.toggleDisable(true);
    var x = this.accountForm.value;
    const payload: UpdateUser = {
      cutomId: this.storage.getItem('customId'),
      email: this.email,
      firstName: x.firstName,
      lastName: x.lastName,
      phone: x.phone,
      allowEmail: x.allowEmail,
    };
    this.authfacde.updateProfile(payload);
  }
}

import { CommonModule, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Toast } from '@ea/models';
import { Subject, takeUntil, timer } from 'rxjs';

@Component({
  selector: 'ea-toast',
  standalone: true,
  imports: [CommonModule, NgIf],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnDestroy {
  private _toast?: Toast;

  @Input() set toast(value: Toast) {
    this._toast = value;
    this.unsubscribe$.next();
    timer(value.duration)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.dispose.emit();
      });
  }

  get message() {
    return this._toast?.message;
  }

  get type() {
    return this._toast?.type;
  }
  @Output() dispose = new EventEmitter<void>();
  private unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}


<div *ngIf="((selected.isDefault || selected.isUserDefault) && !showList) && !isCharityPage">
  <div
    class="p-4 bg-theme-surface text-theme-on-surface mb-3 font-title mb-4 address-card charity-div"
  >
    <div class="justify-content-between row">
      <div class="col-9" *ngIf="!isCharityPage">
        <h1 class="font-bold title">EnableAll will donate to this charity</h1>
      </div>
      <div class="col-9" *ngIf="selected.isDefault">
        <p class="font-bold">EnableAll will donate</p>
      </div>
      <div class="col-3" *ngIf="!isCharityPage">
        <button class="ea-button ea-button-stroked" (click)="displayCharityList()">Change charity</button>
      </div>
    </div>
    <hr class="mt-2" />
    <div class="row container">
      <div class="col-12 row">
        <div
          class="img-container col-3"
          [style.background-image]="
            false
              ? ''
              : 'url(data:image/*;base64,' + selected.logoImageBytes + ')'
          "
        ></div>
        <div class="col-9">
          <div class="row mb-2 mt-1">
            <div class="col-6">
              <span>
                <strong>
                  {{ selected.charityName }}
                </strong>
              </span>
            </div>
            <div class="col-6 text-right">
              <span>
                <strong>{{ selected.areaofFocusNames }}</strong>
              </span>
            </div>
          </div>

          <p>
            {{ selected.description }}
          </p>
          <div class="row mt-4">
            @if(selected.isUserDefault){
              <div class="col-6" >
                <span class="mt-4">   Selected from your account</span>
              </div>
            }
            @else{
            <div class="col-6" >
              <span class="mt-4"> EnableAll charity of the month </span>
            </div>
          }
            <div class="col-6 text-right" *ngIf="selected.isDefault && !selected.isUserDefault">
              <input
                type="checkbox"
                class="form-check-input mr-2"
                [checked]="false"
                (click)="setDefaultCharity()"
                id="{{selected.charityId}}"
              />
              <label class="form-check-label" for="{{selected.charityId}}">
                Save as my preferred charity
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 float-right mt-3 text-right"></div>
    </div>
  </div>
</div>

<div *ngIf="isCharityPage && !selected.isUserDefault && !isSlected">
  <div
    class="p-4 text-theme-on-surface mb-3 font-title mb-4 address-card charity-div"
  >
    <div class="row container">
      <div class="col-12 row">
        <div>
            <p class="font-bold">EnableAll will donate a percentage of our commission to a UK charity. We support and promote a number of charities
              <br><br>
              This month we are supporting:
            </p>
        </div>
        <div
          class="img-container col-3"
          [style.background-image]="
            false
              ? ''
              : 'url(data:image/*;base64,' + enableALLCharity.logoImageBytes + ')'
          "
        ></div>
        <div class="col-lg-9 col-sm-12">
          <div class="row mb-2 mt-1 disctop">
            <div class="col-lg-6 col-sm-12 mt-5">
              <span>
                <strong>
                  {{ enableALLCharity.charityName }}
                </strong>
              </span>
            </div>
            <div class="  col-sm-12 col-lg-6 text-right mt-5 ">
              <span>
                <strong>{{ enableALLCharity.areaofFocusNames }}</strong>
              </span>
            </div>
          </div>
          <div class="row mb-2 mt-1 mobile">
            <div class="col-lg-6 col-sm-12 mt-5 ">
              <span>
                <strong>
                  {{ enableALLCharity.charityName }}
                </strong>
              </span>
            </div>
            <div class="  col-sm-12 col-lg-6 mt-5 ">
              <span>
                <strong>{{ enableALLCharity.areaofFocusNames }}</strong>
              </span>
            </div>
          </div>
        </div>
      </div>

      <p style="font-size: 18;" class="mt-3 font-bold">
        {{ enableALLCharity.description }}
      </p>
      <div class="col-3 float-right mt-3 text-right"></div>
    </div>
  </div>
</div>

<div class="mobile toaster-div" *ngIf="isCharityPage">
  <ea-toast
  style="width: 60% !important;"
  *ngIf="toast"
  [toast]=" toast"
  (dispose) ="closeToast()"
  ></ea-toast>
</div>
<div *ngIf="(!selected.isDefault && !selected.isUserDefault) || showList || isCharityPage">
  <div
    class="p-4 bg-theme-surface text-theme-on-surface mb-3 font-title mb-4 address-card charity-div"
  >

  <div class="col-12" *ngIf="selected.isUserDefault && isCharityPage">
    <p class="font-bold mb-3" style="font-size: 18px;">EnableAll will donate a percentage of our commission to your chosen charity {{selected.charityName}} charity
      <br>       <br>

      To change your chosen charity, please select from the list below
    </p>
    </div>


  <div class="col-12" *ngIf="!selected.isUserDefault && isCharityPage">
    <p class=" mb-3">
      Choose to support a charity by selecting from the list below
    </p>
    </div>
    <h1 class="font-bold" *ngIf=" !isCharityPage">Choose a charity for us to donate to</h1>
    <hr class="mt-1" />
    <div class="mt-3">
      <div [ngClass]="isCharityPage ? 'w-50 disctop' :'disability-selection'">
        <label class="font-bold" for="Disability"> Disability</label>
        <select
          class="form-select"
          [(ngModel)]="selectedDedisability"
          (ngModelChange)="changeDisability($event)"
          id="Disability"
        >
          <option *ngFor="let i of disability" [ngValue]="i">
            {{ i }}
          </option>
        </select>
      </div>
      <div [ngClass]="isCharityPage ? 'w-100 mobile' :'disability-selection mobile'">
        <label for="Disability" class="font-bold"> Disability</label>
        <select
          class="form-select"
          [(ngModel)]="selectedDedisability"
          (ngModelChange)="changeDisability($event)"
          id="Disability"
        >
          <option *ngFor="let i of disability" [ngValue]="i">
            {{ i }}
          </option>
        </select>
      </div>
      <div class="mt-4">
        <div
          *ngFor="let option of charities"
          [ngClass]="
            option != selected
              ? 'col address-option'
              : 'col address-option div-active'
          "
        >
          <div class="contaner justify-content-between row">
            <div class="col-1 mt-2">
              <label>
                <input
                  class="form-radio-input"
                  type="radio"
                  [(ngModel)]="selected"
                  [value]="option"
                  name="charity"
                  (ngModelChange)="onOptionsChanged($event)"
                  id="{{ option.charityName }}"
                />
              </label>
            </div>
            <div class="col-8">
              <h2><label for="{{ option.charityName }}"> {{ option.charityName }}</label></h2>
            </div>
            <div class="col-3">
              <span>
                {{ option.areaofFocusNames }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-container *ngIf="cart$ | async as cart">
  <table class="w-100">
    <!-- <caption class="sr-only">
      Totals
    </caption> -->
    <tbody class="leading-relaxed">
      <tr>
        <td class="text-left" scope="row">
          <span>Basket subtotal</span>
        </td>
        <td class="text-right">
          <h2>{{ total$ | async | currency: cart.currency }}</h2>
        </td>
      </tr>
      <tr>
        <td class="text-left py-3" scope="row">
          <span>Estimated delivery
          </span>
        </td>
        <td class="text-right">
          <h2>{{ estimatedShippingAmount| currency: cart.currency }}</h2>
        </td>
      </tr>
      <tr  class="savings">
        <th class="text-left py-3" scope="row">
          <strong><span>Total to pay</span></strong>
        </th>
        <td class="text-right">
         <strong> <span>{{      this.totalToPay +estimatedShippingAmount | currency: cart.currency }}</span></strong> 
        </td>
        
      </tr>
    </tbody>
  </table>
</ng-container>

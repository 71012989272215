import { Injectable } from '@angular/core';
import { CookieService } from '@ea/auth';
import {
  AddItemRequest,
  Cart,
  CartItem,
  CartResponse,
  ListCartResponse,
  UpdateItemRequest,
} from '@ea/cart';
import { ApiService } from '@ea/services';
import { Observable, of, switchMap } from 'rxjs';
import { FabricCheckoutRequest, ShipMethod } from '../models/cart.model';
import environment from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartCookie = 'cart';

  private baseCartUrl = '/Carts/';

  find(customId: string): Observable<ListCartResponse> {
    return this.api.get<ListCartResponse>(
      `${this.baseCartUrl}Get/Active?customId=${customId}&region=${environment.stripe.country}`
      // `/Fabric/Customer/Cart/ALL/Pending/Get?customId=${customId}&region=${environment.stripe.country}`,
    );
  }

  get(cartId: string): Observable<CartResponse> {
    if (cartId) {
      return this.api.get<CartResponse>(
        `${this.baseCartUrl}Get/Single?cartId=${cartId}&region=${environment.stripe.country}`
      );
    } else {
      return of<CartResponse>();
    }
  }
  

  addItem(request: AddItemRequest): Observable<ListCartResponse> {
    return this.api.post<ListCartResponse>(
      `${this.baseCartUrl}Add/Item?region=${environment.stripe.country}`,
      // `/Fabric/Customer/Cart/Item/Add?region=${environment.stripe.country}`
      request
    );
  }

  applyPromoCode(cartId: string, promoCode: string): Observable<string> {
    return this.api.post<string>(
      `${this.baseCartUrl}apply-promocode/${cartId}/${promoCode}?region=${environment.stripe.country}`,
      {}
    );
  }

  removePromoCode(cartId: string, promoCode: string): Observable<string> {
    return this.api.delete<string>(
      `${this.baseCartUrl}remove-PromoCode/${cartId}/${promoCode}?region=${environment.stripe.country}`
    );
  }

  updateItem(
    cartId: string,
    request: UpdateItemRequest
  ): Observable<CartResponse> {
    return this.api.patch<CartResponse>(
      `${this.baseCartUrl}Update/Items?cartId=${cartId}&region=${environment.stripe.country}`,
      // `/Fabric/Customer/Cart/Items/Update`
      request
    );
  }

  set(cart: Cart) {
    const json = JSON.stringify(cart);
    this.cookieService.update(this.cartCookie, json);
  }

  removeItem(cartId: string, lineItemId: number) {
    return this.api.delete<ListCartResponse>(
      `${this.baseCartUrl}Remove/Item?lineItemId=${lineItemId}&cartId=${cartId}&region=${environment.stripe.country}`
      //  `/Fabric/Customer/Cart/Item/Remove?lineItemId=${lineItemId}&cartId=${cartId}&region=${environment.stripe.country}`,
    );
  }

  addItemsToUserCart(cartId: string, customId: string) {
    return this.api.patch<any>(
      `${this.baseCartUrl}Update/Single?cartId=${cartId}&customId=${customId}&region=${environment.stripe.country}`,
      //`/Fabric/Customer/Cart/Single/Update?cartId=${cartId}&customId=${customId}&region=${environment.stripe.country}`,
      {}
    );
  }

  attachPaymentToCart(request: FabricCheckoutRequest) {
    return this.api.post<{ checkoutComplete: boolean; orderId: string, orderNumber : string }>(
      `${this.baseCartUrl}Checkout?region=${environment.stripe.country}`,
      // `/Fabric/Customer/Cart/Checkout?region=${environment.stripe.country}`
      request
    );
  }

  setAddressToItem(
    cartId: string,
    address: any,
    shipMethod: ShipMethod,
    items: CartItem[]
  ) {
    const request = {
      cartId,
      address,
      shipMethod,
      shipToType: 'SHIP_TO_ADDRESS',
    };

    return this.api
      .post<any>(
        `/Fabric/Customer/Cart/Shipping/Detail/Create?cartId=${cartId}&region=${environment.stripe.country}`,
        request
      )
      .pipe(
        switchMap((response) => {
          return this.updateItemShippingMethod(
            cartId,
            response.shipToId,
            ...items
          );
        })
      );
  }

  updateItemShippingMethod(
    cartId: string,
    shipToId: string,
    ...items: CartItem[]
  ) {
    const request = {
      cartId,
      items: items.map((x) => {
        return { shipToId, lineItemId: x.lineItemId, itemId: x.itemId };
      }),
    };
    return this.api.patch<any>(
      `/Fabric/Customer/Cart/Shipping/Item/Update?cartId=${cartId}&region=${environment.stripe.country}`,
      request
    );
  }

  constructor(
    private readonly cookieService: CookieService,
    private api: ApiService
  ) {}
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BaseComponent, ToastComponent } from '@ea/components';
import { Toast } from '@ea/models';
import { LocalStorageService } from '@ea/storage';
import { CharityService } from 'src/app/services/shared/charityService/charity.service';

@Component({
  selector: 'ea-charity-choose',
  standalone: true,
  imports: [  CommonModule,FormsModule ,ToastComponent],
  templateUrl: './charity-choose.component.html',
  styleUrl: './charity-choose.component.scss'
})
export class CharityChooseComponent extends BaseComponent {
  @Input() disability : any;
  @Input() charities : any;
  @Input() selectedDedisability : any;
  @Input() user: any;
  @Input() customeId? : any;
  @Input() selected : any;
  @Input() isCharityPage : boolean =false;
  @Output() changeDisabilityEvent  = new EventEmitter<any>();
  @Output() onOptionsChangedEvent =  new EventEmitter<any>();
  enableALLCharity :any;
  isSlected : boolean = false;
  toast? : Toast ;

  closeToast(){
    this.toast = undefined;
  }
  showList = false
  changeDisability(event : any){
    this.changeDisabilityEvent.emit(event);
  }

  ngOnInit() {
    this.enableALLCharity = this.charities.find((x:any) => x.isDefault);
  }
onOptionsChanged(event : any){
  this.toast = {
    message: 'Preferred charity set successfully',
    duration: 6000 + Math.ceil('message'.split(' ').length / 120) * 100,
    type: 'success'
  };
  this.isSlected= true;
  this.onOptionsChangedEvent.emit(event);
}

displayCharityList(){
  this.showList = true;
}
setDefaultCharity(){
 this.charityService.insertDefaultCharity(this.user.customId, this.selected.charityId).subscribe(
  x=>{
  }
 );
}
  constructor(private charityService : CharityService){
    super();
  }
}

<div
  class="toast"
  [ngClass]="{
    'toast-successs': type === 'success',
    'toast-warnings': type === 'warning',
    'toast-errors': type === 'error',
    'toast-info': type === 'info'
  }"
>
  <!-- Icon Section -->
  <span class="material-icons toast-icon">
    {{ type === 'success' ? 'check_circle' : type === 'warning' ? 'warning' : type === 'info' ? 'person' : 'error' }}
  </span>
  
  <!-- Message Section -->
  <div *ngIf="message">
    <p class="toast-msg">{{ message }}</p>
  </div>

  <!-- Close Button Section -->
  <span class="material-icons toast-close" (click)="dispose.emit()">close</span>
</div>
